{
  "name": "Homie",
  "version": "1.1.0",
  "scripts": {
    "ng": "ng",
    "build": "ng build && node copy-proxy-server.js",
    "build_dev": "ng build --configuration development && node copy-proxy-server.js",
    "watch": "ng build --watch --configuration development",
    "lint": "ng lint"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "18.1.0",
    "@angular/cdk": "18.0.6",
    "@angular/common": "18.1.0",
    "@angular/compiler": "18.1.0",
    "@angular/core": "18.1.0",
    "@angular/forms": "18.1.0",
    "@angular/material": "18.0.6",
    "@angular/platform-browser": "18.1.0",
    "@angular/platform-browser-dynamic": "18.1.0",
    "@angular/platform-server": "18.1.0",
    "@angular/router": "18.1.0",
    "@angular/ssr": "^18.1.0",
    "@turf/boolean-point-in-polygon": "^7.0.0",
    "chart.js": "^4.4.3",
    "chartjs-plugin-annotation": "^3.0.1",
    "express": "^4.19.2",
    "flowbite": "^2.4.1",
    "flowbite-datepicker": "^1.3.0",
    "google-libphonenumber": "^3.2.35",
    "jwt-decode": "^4.0.0",
    "keycloak-angular": "^16.0.1",
    "keycloak-js": "^25.0.1",
    "leaflet": "^1.9.4",
    "leaflet-draw": "^1.0.4",
    "leaflet-gesture-handling": "^1.2.2",
    "moment": "^2.30.1",
    "ng2-charts": "^6.0.1",
    "ngx-image-compress": "^15.1.6",
    "ngx-quill": "^26.0.5",
    "rxjs": "7.8.1",
    "swiper": "^11.1.4",
    "tslib": "2.6.3",
    "uuid": "^10.0.0",
    "wicket": "^1.3.8",
    "zone.js": "^0.14.7"
  },
  "devDependencies": {
    "@angular/build": "^18.1.0",
    "@angular/cli": "^18.1.0",
    "@angular/compiler-cli": "18.1.0",
    "@angular/language-service": "18.1.0",
    "@angular/localize": "18.1.0",
    "@schematics/angular": "18.1.0",
    "@types/chart.js": "^2.9.41",
    "@types/express": "^4.17.21",
    "@types/geojson": "^7946.0.14",
    "@types/google-libphonenumber": "^7.4.30",
    "@types/jasmine": "5.1.4",
    "@types/leaflet": "^1.9.12",
    "@types/leaflet-draw": "^1.0.11",
    "@types/node": "^20.14.10",
    "@types/supercluster": "^7.1.3",
    "@types/uuid": "^10.0.0",
    "angular-eslint": "18.3.1",
    "autoprefixer": "^10.4.19",
    "browser-sync": "^3.0.2",
    "eslint": "^9.9.1",
    "flowbite-typography": "^1.0.4",
    "jasmine-core": "5.1.2",
    "karma": "~6.4.3",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "ng-extract-i18n-merge": "^2.12.0",
    "postcss": "^8.4.39",
    "prettier": "^3.3.2",
    "prettier-plugin-tailwindcss": "^0.6.5",
    "tailwindcss": "^3.4.4",
    "ts-node": "10.9.2",
    "typescript": "^5.5.3",
    "typescript-eslint": "8.2.0"
  },
  "scarfSettings.enabled": false
}
